import React, { useState } from 'react';
import RestaurantDemo from './components-restaurant/RestaurantDemo';
import WhyChooseUs from './components-home/WhyChooseUs';
import Feature from './components-home/Feature';
import RestaurantPricing from './components-restaurant/RestaurantPricing';
import Contact from './components-home/Contact';
import Notification from './components-home/Notification';

const Restaurants = () => {
    const [notification, setNotification] = useState({ message: '', type: '' });

    const showNotification = (message, type) => {
        setNotification({ message, type });
        setTimeout(() => {
            setNotification({ message: '', type: '' });
        }, 3000);
    };

    return (
        <div>
            {/* Custom Hero Section for Restaurants */}
            <section id="hero" className="hero-section" style={{ 
                position: 'relative',
                width: '100%',
                height: '100vh',
                minHeight: '680px',
                display: 'flex',
                alignItems: 'center',
                overflow: 'hidden'
            }}>
                {/* Background Video/Image */}
                <div style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    zIndex: 1,
                    background: `linear-gradient(to right bottom, rgba(33, 106, 217, 0.95), rgba(27, 89, 179, 0.98))`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center'
                }}>
                    {/* You can replace this div with a video if available */}
                </div>
                
                {/* Animated shapes for visual interest */}
                <div className="shape shape1"></div>
                <div className="shape shape2"></div>
                <div className="shape shape3"></div>
                
                {/* Content */}
                <div className="container" style={{ position: 'relative', zIndex: 10 }}>
                    <div className="row">
                        <div className="col-lg-6 text-white" style={{ animation: 'fadeInUp 1s ease' }}>
                            <h1 className="hero-title" style={{ 
                                fontSize: '3.8rem', 
                                fontWeight: '700', 
                                marginBottom: '20px',
                                lineHeight: '1.2',
                                color: 'white'
                            }}>
                                Your 24/7 AI <br />
                                Phone Assistant
                            </h1>
                            
                            <span className="subtitle" style={{
                                display: 'inline-block',
                                background: 'rgba(255, 138, 94, 0.2)',
                                color: '#FF8A5E',
                                padding: '8px 20px',
                                borderRadius: '30px',
                                fontSize: '16px',
                                fontWeight: '600',
                                marginBottom: '25px',
                                backdropFilter: 'blur(10px)'
                            }}>FOR RESTAURANTS & PIZZA SHOPS</span>
                            
                            <p className="lead" style={{ 
                                fontSize: '1.4rem', 
                                marginBottom: '25px', 
                                maxWidth: '90%',
                                opacity: '0.9',
                                lineHeight: '1.5'
                            }}>
                                Our AI answers even concurrent calls, takes pizza orders, and prints tickets at your restaurant or pizza store.
                            </p>
                            
                            <div className="key-features" style={{
                                marginBottom: '25px',
                                display: 'flex',
                                flexDirection: 'column',
                                gap: '8px'
                            }}>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <span style={{ color: '#FF8A5E', marginRight: '10px', fontSize: '18px' }}>✓</span>
                                    <span style={{ fontSize: '1.1rem' }}>Takes orders & handles all special requests</span>
                                </div>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <span style={{ color: '#FF8A5E', marginRight: '10px', fontSize: '18px' }}>✓</span>
                                    <span style={{ fontSize: '1.1rem' }}>Verifies addresses & schedules deliveries</span>
                                </div>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <span style={{ color: '#FF8A5E', marginRight: '10px', fontSize: '18px' }}>✓</span>
                                    <span style={{ fontSize: '1.1rem' }}>Works 24/7—no missed calls!</span>
                                </div>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <span style={{ color: '#FF8A5E', marginRight: '10px', fontSize: '18px' }}>✓</span>
                                    <span style={{ fontSize: '1.1rem' }}>Integrates with your existing systems</span>
                                </div>
                            </div>
                            
                            <p style={{ 
                                fontSize: '1rem', 
                                marginBottom: '30px',
                                fontStyle: 'italic'
                            }}>
                                No setup fee. No contract. Cancel anytime.
                            </p>
                            
                            <div className="hero-buttons" style={{ 
                                display: 'flex', 
                                gap: '15px',
                                flexWrap: 'wrap'
                            }}>
                                <a href="#demo" className="btn primary-btn" style={{ 
                                    backgroundColor: '#FF8A5E', 
                                    color: 'white', 
                                    padding: '12px 24px',
                                    fontWeight: '600',
                                    borderRadius: '30px',
                                    fontSize: '15px',
                                    boxShadow: '0 10px 20px rgba(255, 138, 94, 0.3)',
                                    display: 'inline-flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    minWidth: '160px',
                                    transition: 'all 0.3s ease',
                                    textDecoration: 'none',
                                    textTransform: 'uppercase',
                                    letterSpacing: '1px'
                                }} onClick={(e) => {
                                    e.preventDefault();
                                    const demoSection = document.getElementById('demo');
                                    if (demoSection) {
                                        const yOffset = -80; // Header height offset
                                        const y = demoSection.getBoundingClientRect().top + window.pageYOffset + yOffset;
                                        window.scrollTo({top: y, behavior: 'smooth'});
                                    }
                                }}>
                                    <i className="icofont-play-alt-1" style={{ marginRight: '8px' }}></i>
                                    Watch Demo
                                </a>
                                <a href="#try-yourself" className="btn secondary-btn" style={{ 
                                    backgroundColor: 'transparent', 
                                    color: 'white', 
                                    padding: '12px 24px',
                                    fontWeight: '600',
                                    borderRadius: '30px',
                                    fontSize: '15px',
                                    border: '2px solid white',
                                    display: 'inline-flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    minWidth: '160px',
                                    transition: 'all 0.3s ease',
                                    textDecoration: 'none',
                                    textTransform: 'uppercase',
                                    letterSpacing: '1px'
                                }} onClick={(e) => {
                                    e.preventDefault();
                                    const tryYourselfSection = document.getElementById('try-yourself');
                                    if (tryYourselfSection) {
                                        const yOffset = -80; // Header height offset
                                        const y = tryYourselfSection.getBoundingClientRect().top + window.pageYOffset + yOffset;
                                        window.scrollTo({top: y, behavior: 'smooth'});
                                    }
                                }}>
                                    <i className="icofont-phone" style={{ marginRight: '8px' }}></i>
                                    Try Yourself
                                </a>
                                <a href="/restaurant-form" className="btn secondary-btn" style={{ 
                                    backgroundColor: 'transparent', 
                                    color: 'white', 
                                    padding: '12px 24px',
                                    fontWeight: '600',
                                    borderRadius: '30px',
                                    fontSize: '15px',
                                    border: '2px solid white',
                                    display: 'inline-flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    minWidth: '160px',
                                    transition: 'all 0.3s ease',
                                    textDecoration: 'none',
                                    textTransform: 'uppercase',
                                    letterSpacing: '1px'
                                }}>
                                    Get Started
                                </a>
                            </div>
                        </div>
                        
                        <div className="col-lg-6 d-flex align-items-center justify-content-center mt-5 mt-lg-0" style={{ animation: 'fadeIn 1.2s ease' }}>
                            <img 
                                src="/assets/img/phone-call.png" 
                                alt="AI Phone Assistant" 
                                style={{ 
                                    maxWidth: '100%',
                                    maxHeight: '675px',
                                    transform: 'scale(1.2)',
                                    marginLeft: '10%'
                                }}
                            />
                        </div>
                    </div>
                </div>
                
                {/* Scroll indicator */}
                <a href="#demo" className="scroll-down" style={{
                    position: 'absolute',
                    bottom: '30px',
                    left: '50%',
                    transform: 'translateX(-50%)',
                    color: 'white',
                    fontSize: '30px',
                    zIndex: 10,
                    animation: 'bounce 2s infinite'
                }} onClick={(e) => {
                    e.preventDefault();
                    const demoSection = document.getElementById('demo');
                    if (demoSection) {
                        const yOffset = -80; // Header height offset
                        const y = demoSection.getBoundingClientRect().top + window.pageYOffset + yOffset;
                        window.scrollTo({top: y, behavior: 'smooth'});
                    }
                }}>
                    <i className="icofont-simple-down"></i>
                </a>
            </section>
            
            <style jsx="true">{`
                @keyframes fadeInUp {
                    from {
                        opacity: 0;
                        transform: translateY(20px);
                    }
                    to {
                        opacity: 1;
                        transform: translateY(0);
                    }
                }
                
                @keyframes bounce {
                    0%, 20%, 50%, 80%, 100% {
                        transform: translateY(0) translateX(-50%);
                    }
                    40% {
                        transform: translateY(-20px) translateX(-50%);
                    }
                    60% {
                        transform: translateY(-10px) translateX(-50%);
                    }
                }
                
                .shape {
                    position: absolute;
                    background: rgba(255, 138, 94, 0.3);
                    border-radius: 50%;
                    z-index: 2;
                }
                
                .shape1 {
                    width: 300px;
                    height: 300px;
                    top: -100px;
                    right: -150px;
                    animation: float 8s ease-in-out infinite;
                }
                
                .shape2 {
                    width: 200px;
                    height: 200px;
                    bottom: -50px;
                    left: -100px;
                    animation: float 10s ease-in-out infinite;
                }
                
                .shape3 {
                    width: 150px;
                    height: 150px;
                    bottom: 150px;
                    right: 10%;
                    animation: float 6s ease-in-out infinite;
                }
                
                @keyframes float {
                    0% {
                        transform: translateY(0px);
                    }
                    50% {
                        transform: translateY(-20px);
                    }
                    100% {
                        transform: translateY(0px);
                    }
                }
                
                .primary-btn:hover {
                    transform: translateY(-5px);
                    box-shadow: 0 15px 25px rgba(255, 138, 94, 0.4);
                }
                
                .secondary-btn:hover {
                    background-color: white;
                    color: #333;
                }
                
                /* Mobile responsiveness */
                @media (max-width: 991px) {
                    .hero-section {
                        height: auto !important;
                        min-height: auto !important;
                        padding: 100px 0 80px !important;
                    }
                    
                    .hero-title {
                        font-size: 2.8rem !important;
                    }
                    
                    .lead {
                        font-size: 1.2rem !important;
                    }
                    
                    .key-features span:last-child {
                        font-size: 1rem !important;
                    }
                    
                    .hero-buttons {
                        justify-content: center;
                    }
                    
                    .primary-btn, .secondary-btn {
                        width: 100%;
                        margin-bottom: 10px;
                        min-width: 0 !important;
                    }
                    
                    .mobile-padding {
                        padding: 60px 0 !important;
                    }
                    
                    .section-title h2 {
                        font-size: 28px !important;
                    }
                    
                    .mobile-title {
                        font-size: 24px !important;
                    }
                    
                    .mobile-list li {
                        font-size: 14px !important;
                    }
                    
                    .mobile-buttons {
                        flex-direction: column;
                    }
                    
                    .mobile-buttons a {
                        width: 100%;
                        text-align: center;
                    }
                    
                    .website-preview {
                        transform: none !important;
                        margin-top: 30px;
                    }
                }
                
                @media (max-width: 767px) {
                    .hero-title {
                        font-size: 2.2rem !important;
                    }
                    
                    .shape {
                        display: none;
                    }
                    
                    .m-50px-b {
                        margin-bottom: 30px !important;
                    }
                    
                    .p-4 {
                        padding: 15px !important;
                    }
                }
            `}</style>
            
            <div id="demo">
                <RestaurantDemo />
            </div>
            
            <div id="why-choose-us">
                <WhyChooseUs />
            </div>
            
            <div id="feature">
                <Feature />
            </div>
            
            <div id="pricing">
                <RestaurantPricing />
            </div>
            
            {/* Other Restaurant Services Section */}
            <section id="other-services" className="p-80px-tb bg-light mobile-padding">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="section-title text-center m-50px-b">
                                <h2>Other Restaurant Services</h2>
                                <p>Beyond AI phone solutions, we help restaurants succeed with these additional services</p>
                            </div>
                        </div>
                    </div>
                    
                    <div className="row align-items-center">
                        <div className="col-md-6 mb-4 mb-md-0">
                            <div className="service-info p-4">
                                <h3 style={{ color: '#333', marginBottom: '15px', fontSize: '28px' }} className="mobile-title">
                                    Custom Restaurant Website Design
                                </h3>
                                <p style={{ fontSize: '16px', lineHeight: '1.6', marginBottom: '20px' }}>
                                    Elevate your restaurant's online presence with a custom-designed website that showcases your menu, ambiance, and unique offerings. Our restaurant-specific websites are built to boost sales and dramatically improve your search rankings with proven SEO techniques.
                                </p>
                                <ul style={{ paddingLeft: '20px', marginBottom: '25px' }} className="mobile-list">
                                    <li style={{ marginBottom: '10px', fontSize: '16px' }}>
                                        <span style={{ color: '#FF8A5E', fontWeight: 'bold', marginRight: '8px' }}>✓</span>
                                        Highlight your menu with beautiful food photography to drive more orders
                                    </li>
                                    <li style={{ marginBottom: '10px', fontSize: '16px' }}>
                                        <span style={{ color: '#FF8A5E', fontWeight: 'bold', marginRight: '8px' }}>✓</span>
                                        Streamline online ordering for maximum convenience and 35%+ increase in sales
                                    </li>
                                    <li style={{ marginBottom: '10px', fontSize: '16px' }}>
                                        <span style={{ color: '#FF8A5E', fontWeight: 'bold', marginRight: '8px' }}>✓</span>
                                        Optimize for local search with advanced SEO to attract nearby customers and boost Google rankings by 70%+
                                    </li>
                                    <li style={{ marginBottom: '10px', fontSize: '16px' }}>
                                        <span style={{ color: '#FF8A5E', fontWeight: 'bold', marginRight: '8px' }}>✓</span>
                                        Integrate with reservation systems and delivery platforms for a seamless customer experience
                                    </li>
                                </ul>
                                
                                <div style={{ display: 'flex', flexWrap: 'wrap', gap: '15px' }} className="mobile-buttons">
                                    <a href="https://www.sohopizza.com" target="_blank" rel="noopener noreferrer" 
                                        style={{ 
                                            display: 'inline-block',
                                            padding: '10px 25px',
                                            backgroundColor: '#FF8A5E',
                                            color: 'white',
                                            borderRadius: '30px',
                                            textDecoration: 'none',
                                            fontWeight: '600',
                                            fontSize: '16px',
                                            boxShadow: '0 5px 15px rgba(255, 138, 94, 0.3)',
                                            transition: 'all 0.3s ease'
                                        }}>
                                        View Example: SohoPizza.com
                                    </a>
                                    
                                    <a href="#contact" 
                                        style={{ 
                                            display: 'inline-block',
                                            padding: '10px 25px',
                                            backgroundColor: 'white',
                                            color: '#333',
                                            borderRadius: '30px',
                                            textDecoration: 'none',
                                            fontWeight: '600',
                                            fontSize: '16px',
                                            border: '2px solid #FF8A5E',
                                            boxShadow: '0 5px 15px rgba(0, 0, 0, 0.05)',
                                            transition: 'all 0.3s ease'
                                        }}>
                                        <i className="icofont-paper" style={{ marginRight: '8px' }}></i>
                                        Request Website Quote
                                    </a>
                                </div>
                            </div>
                        </div>
                        
                        <div className="col-md-6">
                            <div className="website-preview" style={{ 
                                position: 'relative',
                                borderRadius: '8px',
                                overflow: 'hidden',
                                boxShadow: '0 15px 35px rgba(0, 0, 0, 0.1)',
                                transform: 'perspective(1000px) rotateY(5deg)',
                            }}>
                                <div style={{ 
                                    padding: '15px',
                                    background: '#f6f6f6',
                                    borderTopLeftRadius: '8px',
                                    borderTopRightRadius: '8px',
                                    display: 'flex',
                                    alignItems: 'center'
                                }}>
                                    <div style={{ display: 'flex', marginRight: 'auto' }}>
                                        <span style={{ height: '12px', width: '12px', borderRadius: '50%', backgroundColor: '#ff5f57', marginRight: '6px' }}></span>
                                        <span style={{ height: '12px', width: '12px', borderRadius: '50%', backgroundColor: '#ffbd2e', marginRight: '6px' }}></span>
                                        <span style={{ height: '12px', width: '12px', borderRadius: '50%', backgroundColor: '#28c941' }}></span>
                                    </div>
                                    <div style={{ 
                                        textAlign: 'center', 
                                        flex: '1',
                                        fontSize: '14px',
                                        fontWeight: '500',
                                        color: '#666'
                                    }}>
                                        www.sohopizza.com
                                    </div>
                                </div>
                                
                                {/* Image Carousel */}
                                <div id="sohopizza-carousel" className="carousel slide" data-ride="carousel">
                                    {/* Indicators */}
                                    <ol className="carousel-indicators">
                                        <li data-target="#sohopizza-carousel" data-slide-to="0" className="active"></li>
                                        <li data-target="#sohopizza-carousel" data-slide-to="1"></li>
                                        <li data-target="#sohopizza-carousel" data-slide-to="2"></li>
                                    </ol>
                                    
                                    {/* Slides */}
                                    <div className="carousel-inner">
                                        <div className="carousel-item active">
                                            <img 
                                                src={`${process.env.PUBLIC_URL}/assets/img/sohowebsite2.png`}
                                                alt="SohoPizza Website - Menu" 
                                                style={{ 
                                                    width: '100%', 
                                                    display: 'block',
                                                    border: '1px solid #ddd'
                                                }}
                                                loading="lazy"
                                            />
                                        </div>
                                        <div className="carousel-item">
                                            <img 
                                                src={`${process.env.PUBLIC_URL}/assets/img/sohowebsite1.png`}
                                                alt="SohoPizza Website - Home" 
                                                style={{ 
                                                    width: '100%', 
                                                    display: 'block',
                                                    border: '1px solid #ddd'
                                                }}
                                                loading="lazy"
                                            />
                                        </div>
                                        <div className="carousel-item">
                                            <img 
                                                src={`${process.env.PUBLIC_URL}/assets/img/sohowebsite3.png`}
                                                alt="SohoPizza Website - Order" 
                                                style={{ 
                                                    width: '100%', 
                                                    display: 'block',
                                                    border: '1px solid #ddd'
                                                }}
                                                loading="lazy"
                                            />
                                        </div>
                                    </div>
                                    
                                    {/* Controls */}
                                    <a className="carousel-control-prev" href="#sohopizza-carousel" role="button" data-slide="prev">
                                        <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                                        <span className="sr-only">Previous</span>
                                    </a>
                                    <a className="carousel-control-next" href="#sohopizza-carousel" role="button" data-slide="next">
                                        <span className="carousel-control-next-icon" aria-hidden="true"></span>
                                        <span className="sr-only">Next</span>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            
            <Contact showNotification={showNotification} hideRestaurantButton={true} />
            <Notification notification={notification} onClose={() => setNotification({ message: '', type: '' })} />
        </div>
    );
};

export default Restaurants;