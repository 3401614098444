import React, { Component } from 'react';

class Demo extends Component {
    render() {
        return (
            <section id="demo" className="p-80px-tb bg-light">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-8 offset-sm-2">
                            <div className="section-title text-center m-50px-b">
                                <h2>Watch How Our AI Works</h2>
                                <p>See our AI Phone Assistant in action and experience the future of customer service.</p>
                            </div>
                        </div>
                    </div>
                    <div className="row justify-content-center">
                        <div className="col-lg-10">
                            <div className="video-demo-wrapper text-center">
                                <div className="video-container" style={{ 
                                    position: 'relative',
                                    overflow: 'hidden',
                                    paddingTop: '56.25%',
                                    marginBottom: '50px',
                                    borderRadius: '10px',
                                    boxShadow: '0 15px 30px rgba(0, 0, 0, 0.1)'
                                }}>
                                    <iframe 
                                        style={{
                                            position: 'absolute',
                                            top: '0',
                                            left: '0',
                                            width: '100%',
                                            height: '100%',
                                            border: 'none'
                                        }}
                                        src="https://www.youtube.com/embed/Q-6cXThmTF0" 
                                        title="AI Bunny Demo"
                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                        allowFullScreen>
                                    </iframe>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    <div className="row">
                        <div className="col-sm-8 offset-sm-2">
                            <div className="section-title text-center m-50px-b">
                                <h2>Try Our AI Phone Assistant Yourself</h2>
                                <p>Experience our AI in action by calling our demo restaurant line right now.</p>
                            </div>
                        </div>
                    </div>
                    <div className="row justify-content-center">
                        <div className="col-lg-8">
                            <div className="try-it-yourself-box" style={{ 
                                backgroundColor: 'white', 
                                border: '2px solid #FF8A5E', 
                                borderRadius: '12px', 
                                padding: '40px 35px',
                                margin: '20px 0',
                                boxShadow: '0 10px 30px rgba(0, 0, 0, 0.08)',
                                textAlign: 'center'
                            }}>
                                <div style={{ marginBottom: '25px' }}>
                                    <i className="icofont-phone-circle" style={{ 
                                        fontSize: '60px', 
                                        color: '#FF8A5E', 
                                        display: 'block',
                                        marginBottom: '15px'
                                    }}></i>
                                    <h3 style={{ 
                                        color: '#333', 
                                        fontSize: '28px', 
                                        marginBottom: '15px',
                                        fontWeight: '700'
                                    }}>
                                        Call Our "Trial Pizza Restaurant"
                                    </h3>
                                    <p style={{ 
                                        fontSize: '18px', 
                                        marginBottom: '30px',
                                        maxWidth: '600px',
                                        margin: '0 auto 30px'
                                    }}>
                                        Place a pizza order, ask about menu items, check business hours, or inquire about special offers. Our AI will handle it all!
                                    </p>
                                </div>
                                
                                <a href="tel:2094881905" style={{ 
                                    display: 'inline-block',
                                    fontSize: '36px', 
                                    fontWeight: 'bold',
                                    color: 'white',
                                    textDecoration: 'none',
                                    background: '#FF8A5E',
                                    padding: '15px 40px',
                                    borderRadius: '40px',
                                    boxShadow: '0 10px 20px rgba(255, 138, 94, 0.3)',
                                    transition: 'all 0.3s ease',
                                    position: 'relative'
                                }}>
                                    <i className="icofont-ui-call" style={{ marginRight: '15px' }}></i>
                                    (209) 488-1905
                                </a>
                                
                                <p style={{ 
                                    fontSize: '15px', 
                                    marginTop: '25px',
                                    color: '#777',
                                    fontStyle: 'italic'
                                }}>
                                    Regular rates apply. This is a demo line and no actual orders will be fulfilled.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default Demo; 