import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { HashLink as HLink } from 'react-router-hash-link';
import './RestaurantHeader.css';

class RestaurantHeader extends Component {
   constructor(props) {
      super(props);
      this.state = {
         scrolled: false
      };
   }

   componentDidMount() {
      if (this.props.alwaysVisible) {
         window.scrollTo(0, 0); // Scroll to the top when the page loads
      }
      
      window.addEventListener('scroll', this.handleScroll);
   }
   
   componentWillUnmount() {
      window.removeEventListener('scroll', this.handleScroll);
   }
   
   handleScroll = () => {
      if (window.scrollY > 50) {
         this.setState({ scrolled: true });
      } else {
         this.setState({ scrolled: false });
      }
   }

   render() {
      let imgattr = 'image';
      let { alwaysVisible } = this.props;
      const { scrolled } = this.state;

      return (
          <div>
             <header id="home" className={alwaysVisible ? 'visible-header' : ''}>
                <nav className={`navbar navbar-expand-lg header-nav fixed-top light-header restaurant-header ${scrolled ? 'scrolled' : ''} ${alwaysVisible ? 'fixed-header' : ''}`}>
                   <div className="container">
                      <Link className="navbar-brand" to="/">
                         <img src={process.env.PUBLIC_URL + '/assets/img/restaurant-logo.png'} alt={imgattr} style={{ width: '375px', height: 'auto' }} />
                      </Link>
                      <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarCodeply">
                         <i className="icofont-navigation-menu"></i>
                      </button>
                      <div className="collapse navbar-collapse" id="navbarCodeply">
                        <ul className="nav navbar-nav ml-auto">
                          <li><HLink className="nav-link" smooth to={`/restaurants#why-choose-us`}><span>Why AI?</span></HLink></li>
                          <li><HLink className="nav-link" smooth to={`/restaurants#pricing`}><span>Pricing</span></HLink></li>
                          <li><Link className="nav-link" to="/"><span>Other Businesses</span></Link></li>
                          <li><a className="nav-link login-link" href="https://panel.aibunny.tech" target="_blank" rel="noopener noreferrer"><span>Login</span></a></li>
                          <li className="cta-button">
                            <Link className="btn-get-started pulse" to="/restaurant-form">Get Started</Link>
                          </li>
                        </ul>
                      </div>
                   </div>
                </nav>
             </header>
          </div>
      );
   }
}

export default RestaurantHeader; 