import React, { Component } from 'react';

class RestaurantDemo extends Component {
    render() {
        return (
            <section id="demo" className="p-80px-tb bg-light mobile-section" style={{
                scrollMarginTop: '80px',
                paddingTop: '100px',
                position: 'relative'
            }}>
                {/* Hidden clearfix element to account for fixed header */}
                <div style={{
                    height: '20px',
                    width: '100%',
                    position: 'absolute',
                    top: 0,
                    left: 0
                }}></div>
                
                <div className="container">
                    <div className="row">
                        <div className="col-sm-8 offset-sm-2">
                            <div className="section-title text-center m-50px-b">
                                <h2 className="mobile-heading" style={{ position: 'relative', zIndex: 2 }}>Watch How Our AI Works</h2>
                                <p>See our AI Phone Assistant in action and experience the future of customer service.</p>
                            </div>
                        </div>
                    </div>
                    <div className="row justify-content-center">
                        <div className="col-lg-10">
                            <div className="video-demo-wrapper text-center">
                                <div className="video-container" style={{ 
                                    position: 'relative',
                                    overflow: 'hidden',
                                    paddingTop: '56.25%',
                                    marginBottom: '50px',
                                    borderRadius: '10px',
                                    boxShadow: '0 15px 30px rgba(0, 0, 0, 0.1)'
                                }}>
                                    <iframe 
                                        style={{
                                            position: 'absolute',
                                            top: '0',
                                            left: '0',
                                            width: '100%',
                                            height: '100%',
                                            border: 'none'
                                        }}
                                        src="https://www.youtube.com/embed/Q-6cXThmTF0" 
                                        title="AI Bunny Demo"
                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                        allowFullScreen>
                                    </iframe>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    <div id="try-yourself" className="row">
                        <div className="col-sm-8 offset-sm-2">
                            <div className="section-title text-center m-50px-b">
                                <h2 className="mobile-heading">Try Our AI Phone Assistant Yourself</h2>
                                <p>Experience our AI in action by calling our demo restaurant line right now.</p>
                            </div>
                        </div>
                    </div>
                    <div className="row justify-content-center">
                        <div className="col-lg-8">
                            <div className="try-it-yourself-box" style={{ 
                                backgroundColor: 'white', 
                                border: '2px solid #FF8A5E', 
                                borderRadius: '12px', 
                                padding: '40px 35px',
                                margin: '20px 0',
                                boxShadow: '0 10px 30px rgba(0, 0, 0, 0.08)',
                                textAlign: 'center'
                            }}>
                                <div style={{ marginBottom: '25px' }}>
                                    <i className="icofont-phone-circle" style={{ 
                                        fontSize: '60px', 
                                        color: '#FF8A5E', 
                                        display: 'block',
                                        marginBottom: '15px'
                                    }}></i>
                                    <h3 style={{ 
                                        color: '#333', 
                                        fontSize: '28px', 
                                        marginBottom: '15px',
                                        fontWeight: '700'
                                    }} className="mobile-phone-heading">
                                        Call Our "Trial Pizza Restaurant"
                                    </h3>
                                    <p style={{ 
                                        fontSize: '18px', 
                                        marginBottom: '30px',
                                        maxWidth: '600px',
                                        margin: '0 auto 30px'
                                    }} className="mobile-description">
                                        Place a pizza order, ask about menu items, check business hours, or inquire about special offers. Our AI will handle it all!
                                    </p>
                                </div>
                                
                                <a href="tel:2094881905" className="phone-button" style={{ 
                                    display: 'inline-block',
                                    fontSize: '36px', 
                                    fontWeight: 'bold',
                                    color: 'white',
                                    textDecoration: 'none',
                                    background: '#FF8A5E',
                                    padding: '15px 40px',
                                    borderRadius: '40px',
                                    boxShadow: '0 10px 20px rgba(255, 138, 94, 0.3)',
                                    transition: 'all 0.3s ease',
                                    position: 'relative'
                                }}>
                                    <i className="icofont-ui-call" style={{ marginRight: '15px' }}></i>
                                    (209) 488-1905
                                </a>
                                
                                <p style={{ 
                                    fontSize: '15px', 
                                    marginTop: '25px',
                                    color: '#777',
                                    fontStyle: 'italic'
                                }}>
                                    Regular rates apply. This is a demo line and no actual orders will be fulfilled.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>

                <style jsx="true">{`
                    @media (max-width: 991px) {
                        .mobile-section {
                            padding: 60px 0 !important;
                        }
                        
                        .mobile-heading {
                            font-size: 28px !important;
                        }
                        
                        .try-it-yourself-box {
                            padding: 30px 20px !important;
                        }
                        
                        .mobile-phone-heading {
                            font-size: 24px !important;
                        }
                        
                        .mobile-description {
                            font-size: 16px !important;
                        }
                        
                        .phone-button {
                            font-size: 28px !important;
                            padding: 12px 30px !important;
                            width: 100%;
                        }
                    }
                    
                    @media (max-width: 767px) {
                        .mobile-heading {
                            font-size: 24px !important;
                        }
                        
                        .m-50px-b {
                            margin-bottom: 30px !important;
                        }
                    }
                    
                    @media (max-width: 480px) {
                        .phone-button {
                            font-size: 24px !important;
                            padding: 10px 20px !important;
                        }
                        
                        .phone-button i {
                            margin-right: 10px !important;
                        }
                    }
                `}</style>
            </section>
        );
    }
}

export default RestaurantDemo; 