import React, { Component } from 'react';
import homedata from '../../data/homedata.json';
import { HashLink as HLink } from 'react-router-hash-link';

class Pricing extends Component {
    render() {
        return (
            <section id="pricing" className="p-80px-tb parallax bg-overlay opacity-5" style={{backgroundImage: 'url(./assets/img/pricing-bg.jpg)'}}>
                <div className="container">
                    <div className="row">
                        <div className="col-sm-8 offset-sm-2">
                            <div className="section-title text-center m-50px-b" style={{color: '#e0e0e0'}}>
                                <h2>{homedata.pricingtable.sectiontitle}</h2>
                                <div className="restaurant-icon text-center mb-4">
                                    <i className="icofont-restaurant" style={{fontSize: '48px', color: '#FF5722'}}></i>
                                </div>
                                <p>
                                    {homedata.pricingtable.description.split('\n').map((line, index) => (
                                        <span key={index}>{line}<br/></span>
                                    ))}
                                </p>
                            </div>
                        </div>
                    </div>

                    {/* Restaurant Pricing - Featured Section */}
                    <div className="row justify-content-center mb-5">
                        <div className="col-lg-8">
                            <div className="featured-pricing-card" style={{
                                backgroundColor: 'rgba(255, 255, 255, 0.9)',
                                borderRadius: '12px',
                                padding: '30px',
                                boxShadow: '0 10px 30px rgba(0, 0, 0, 0.2)',
                                position: 'relative',
                                overflow: 'hidden'
                            }}>
                                
                                <div className="row align-items-center">
                                    <div className="col-md-6">
                                        <div style={{display: 'flex', alignItems: 'center', marginBottom: '15px'}}>
                                            <i className="icofont-phone-circle" style={{fontSize: '32px', color: '#4F46E5', marginRight: '10px'}}></i>
                                            <h3 style={{color: '#333', fontSize: '28px', margin: 0}}>Restaurant Phone Calls</h3>
                                        </div>
                                        <div style={{display: 'flex', alignItems: 'baseline', marginBottom: '20px'}}>
                                            <span style={{fontSize: '40px', fontWeight: 'bold', color: '#4F46E5'}}>$0.43</span>
                                            <span style={{marginLeft: '5px', color: '#666'}}>/minute</span>
                                        </div>
                                        <p style={{color: '#555', marginBottom: '20px', fontSize: '16px'}}>
                                            Simple, transparent pricing for restaurant phone orders. Average call duration is approximately 2 minutes per order.
                                        </p>
                                        <HLink to={`/restaurant-form`} className="btn btn-default btn-filled animated-btn" style={{
                                            backgroundColor: '#4F46E5',
                                            borderColor: '#4F46E5',
                                        }}>Get Started Now</HLink>
                                    </div>
                                    <div className="col-md-6">
                                        <ul style={{listStyle: 'none', padding: 0, color: '#444'}}>
                                            {homedata.pricingtable.tables[0].features.map((feature, i) => (
                                                <li key={i} style={{
                                                    padding: '8px 0',
                                                    display: 'flex',
                                                    alignItems: 'center'
                                                }}>
                                                    <span style={{
                                                        display: 'inline-block',
                                                        width: '20px',
                                                        height: '20px',
                                                        backgroundColor: '#4F46E5',
                                                        borderRadius: '50%',
                                                        marginRight: '10px',
                                                        position: 'relative'
                                                    }}>
                                                        <span style={{
                                                            position: 'absolute',
                                                            left: '6px',
                                                            top: '3px',
                                                            color: 'white',
                                                            fontSize: '12px'
                                                        }}>✓</span>
                                                    </span>
                                                    {feature}
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* Other Business Plans */}
                    <div className="row">
                        <div className="col-12 text-center">
                            <h3 style={{color: '#e0e0e0', marginBottom: '30px', fontSize: '24px'}}>Other Business Solutions</h3>
                            <p style={{color: '#e0e0e0', marginBottom: '30px'}}>Not a restaurant? We offer custom solutions for all types of businesses.</p>
                            <HLink to={`/#contact`} className="btn btn-default btn-filled animated-btn mb-5">Request a Custom Quote</HLink>
                        </div>
                    </div>
                    
                    <div className="row justify-content-md-center">
                        {homedata.pricingtable.tables.slice(1).map((data, i) => {
                            return <div key={i} className="col-lg-4 col-md-6">
                                <div className="single-pricing text-center m-10px-tb" style={{opacity: '0.85'}}>
                                    <div className="pricing-head p-60px-lr lg-p-30px-lr">
                                        <div className="pricing-head-text">
                                            <div className="package-price">
                                                <span className="price">{data.currency}{data.price}</span><span className="validity">{data.duration}</span>
                                            </div>
                                            <div className="package-name">
                                                <h5>{data.title}</h5>
                                            </div>
                                        </div>
                                        <span className="wave"></span>
                                        <span className="wave"></span>
                                        <span className="wave"></span>
                                    </div>
                                    <div className="pricing-body p-60px-lr lg-p-30px-lr">
                                        <ul>
                                            {data.features.map( ( feature, i  ) =>
                                                <li key={i}>{feature}</li>
                                            )}
                                        </ul>
                                    </div>
                                    <div className="pricing-footer p-60px-lr lg-p-30px-lr">
                                        <HLink to={`/#contact`} className="btn btn-default btn-filled animated-btn">Request a Quote</HLink>
                                    </div>
                                </div>
                            </div>
                        } ) }
                    </div>

                    {/* Bottom Restaurant CTA */}
                    <div className="row mt-5 pt-4">
                        <div className="col-lg-10 offset-lg-1">
                            <div style={{
                                backgroundColor: 'rgba(79, 70, 229, 0.1)', 
                                borderRadius: '8px',
                                padding: '30px',
                                borderLeft: '5px solid #4F46E5',
                                textAlign: 'center'
                            }}>
                                <div className="row align-items-center">
                                    <div className="col-md-8">
                                        <h3 style={{color: '#e0e0e0', marginBottom: '10px'}}>Ready to transform your restaurant's phone service?</h3>
                                        <p style={{color: '#cccccc'}}>Our AI phone agents handle orders, reservations, and inquiries while you focus on providing great food and service.</p>
                                    </div>
                                    <div className="col-md-4 text-md-right">
                                        <HLink to={`/restaurant-form`} className="btn btn-default btn-filled animated-btn" style={{
                                            backgroundColor: '#FF5722',
                                            borderColor: '#FF5722',
                                        }}>Apply as a Restaurant</HLink>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default Pricing;
