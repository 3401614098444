import React from 'react';
import { Link } from 'react-router-dom';

const Footer = () => {
    return (
        <footer className="footer" style={{ backgroundColor: '#1a1a1a', padding: '40px 0 20px 0' }}>
            <div className="container text-center">
                <div className="row justify-content-center">
                    <div className="col-12 mb-4">
                        <img src={process.env.PUBLIC_URL + '/assets/img/logo.png'} alt="AI Bunny" style={{ width: '250px', height: 'auto' }} />
                    </div>
                </div>
                <div className="row justify-content-center mb-4">
                    <div className="col-auto">
                        <Link to="/privacy-policy" className="d-flex flex-column align-items-center text-decoration-none" style={{ color: '#fff' }}>
                            <div className="mb-2" style={{ backgroundColor: '#fff', borderRadius: '50%', width: '50px', height: '50px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <i className="icofont-lock" style={{ fontSize: '24px', color: '#000' }}></i>
                            </div>
                            <span>Privacy Policy</span>
                        </Link>
                    </div>
                    <div className="col-auto" style={{ marginLeft: '30px' }}>
                        <Link to="/terms-of-service" className="d-flex flex-column align-items-center text-decoration-none" style={{ color: '#fff' }}>
                            <div className="mb-2" style={{ backgroundColor: '#fff', borderRadius: '50%', width: '50px', height: '50px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <i className="icofont-file-text" style={{ fontSize: '24px', color: '#000' }}></i>
                            </div>
                            <span>Terms of Service</span>
                        </Link>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <p style={{ color: '#fff', marginBottom: 0 }}>© AI Bunny 2025. All Rights Reserved.</p>
                    </div>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
