import React, { Component } from 'react';
import homedata from '../../data/homedata.json';
import { HashLink as HLink } from 'react-router-hash-link';

class RestaurantPricing extends Component {
    render() {
        return (
            <section id="pricing" className="p-80px-tb parallax bg-overlay opacity-5 mobile-section" style={{backgroundImage: 'url(./assets/img/pricing-bg.jpg)'}}>
                <div className="container">
                    <div className="row">
                        <div className="col-sm-8 offset-sm-2">
                            <div className="section-title text-center m-50px-b" style={{color: '#e0e0e0'}}>
                                <h2 className="mobile-heading">{homedata.pricingtable.sectiontitle}</h2>
                                <div className="restaurant-icon text-center mb-4">
                                    <i className="icofont-restaurant" style={{fontSize: '48px', color: '#FF5722'}}></i>
                                </div>
                                <p className="mobile-text">
                                    {homedata.pricingtable.description.split('\n').map((line, index) => (
                                        <span key={index}>{line}<br/></span>
                                    ))}
                                </p>
                            </div>
                        </div>
                    </div>

                    {/* Restaurant Pricing - Featured Section */}
                    <div className="row justify-content-center mb-5">
                        <div className="col-lg-8">
                            <div className="featured-pricing-card mobile-card" style={{
                                backgroundColor: 'rgba(255, 255, 255, 0.9)',
                                borderRadius: '12px',
                                padding: '30px',
                                boxShadow: '0 10px 30px rgba(0, 0, 0, 0.2)',
                                position: 'relative',
                                overflow: 'hidden'
                            }}>
                                
                                <div className="row align-items-center">
                                    <div className="col-md-6 mb-4 mb-md-0">
                                        <div style={{display: 'flex', alignItems: 'center', marginBottom: '15px'}}>
                                            <i className="icofont-phone-circle" style={{fontSize: '32px', color: '#4F46E5', marginRight: '10px'}}></i>
                                            <h3 style={{color: '#333', fontSize: '28px', margin: 0}} className="mobile-price-title">Restaurant Phone Calls</h3>
                                        </div>
                                        <div style={{display: 'flex', alignItems: 'baseline', marginBottom: '20px'}}>
                                            <span style={{fontSize: '40px', fontWeight: 'bold', color: '#4F46E5'}} className="mobile-price">$0.43</span>
                                            <span style={{marginLeft: '5px', color: '#666'}}>/minute</span>
                                        </div>
                                        <p style={{color: '#555', marginBottom: '20px', fontSize: '16px'}} className="mobile-description">
                                            Simple, transparent pricing for restaurant phone orders. Average call duration is approximately 2 minutes per order.
                                        </p>
                                        <HLink to={`/restaurant-form`} className="btn btn-default btn-filled animated-btn mobile-btn" style={{
                                            backgroundColor: '#4F46E5',
                                            borderColor: '#4F46E5',
                                            display: 'inline-block',
                                            padding: '12px 25px',
                                            width: 'auto',
                                        }}>Get Started Now</HLink>
                                    </div>
                                    <div className="col-md-6">
                                        <ul style={{listStyle: 'none', padding: 0, color: '#444'}} className="mobile-feature-list">
                                            {homedata.pricingtable.tables[0].features.map((feature, i) => (
                                                <li key={i} style={{
                                                    padding: '8px 0',
                                                    display: 'flex',
                                                    alignItems: 'center'
                                                }} className="mobile-feature-item">
                                                    <span style={{
                                                        display: 'inline-block',
                                                        width: '20px',
                                                        height: '20px',
                                                        backgroundColor: '#4F46E5',
                                                        borderRadius: '50%',
                                                        marginRight: '10px',
                                                        position: 'relative'
                                                    }}>
                                                        <span style={{
                                                            position: 'absolute',
                                                            left: '6px',
                                                            top: '3px',
                                                            color: 'white',
                                                            fontSize: '12px'
                                                        }}>✓</span>
                                                    </span>
                                                    {feature}
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* Bottom Restaurant CTA */}
                    <div className="row mt-5 pt-4">
                        <div className="col-lg-10 offset-lg-1">
                            <div className="mobile-cta" style={{
                                backgroundColor: 'rgba(79, 70, 229, 0.1)', 
                                borderRadius: '8px',
                                padding: '30px',
                                borderLeft: '5px solid #4F46E5',
                                textAlign: 'center'
                            }}>
                                <div className="row align-items-center">
                                    <div className="col-md-8 mb-4 mb-md-0">
                                        <h3 style={{color: '#e0e0e0', marginBottom: '10px'}} className="mobile-cta-title">Ready to transform your restaurant's phone service?</h3>
                                        <p style={{color: '#cccccc'}} className="mobile-cta-text">Our AI phone agents handle orders, reservations, and inquiries while you focus on providing great food and service.</p>
                                    </div>
                                    <div className="col-md-4 text-md-right">
                                        <HLink to={`/restaurant-form`} className="btn btn-default btn-filled animated-btn mobile-btn" style={{
                                            backgroundColor: '#FF5722',
                                            borderColor: '#FF5722',
                                            display: 'inline-block',
                                            padding: '12px 25px',
                                        }}>Apply as a Restaurant</HLink>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <style jsx="true">{`
                    @media (max-width: 991px) {
                        .mobile-section {
                            padding: 60px 0 !important;
                        }
                        
                        .mobile-heading {
                            font-size: 28px !important;
                        }
                        
                        .mobile-text {
                            font-size: 15px !important;
                        }
                        
                        .mobile-card {
                            padding: 20px !important;
                        }
                        
                        .mobile-price-title {
                            font-size: 22px !important;
                        }
                        
                        .mobile-price {
                            font-size: 32px !important;
                        }
                        
                        .mobile-description {
                            font-size: 14px !important;
                        }
                        
                        .mobile-feature-list {
                            margin-top: 15px;
                        }
                        
                        .mobile-feature-item {
                            font-size: 14px !important;
                        }
                        
                        .mobile-btn {
                            width: 100% !important;
                        }
                        
                        .mobile-cta {
                            padding: 20px 15px !important;
                            text-align: center !important;
                        }
                        
                        .mobile-cta-title {
                            font-size: 22px !important;
                        }
                        
                        .mobile-cta-text {
                            font-size: 14px !important;
                        }
                    }
                    
                    @media (max-width: 767px) {
                        .mobile-heading {
                            font-size: 24px !important;
                        }
                        
                        .restaurant-icon i {
                            font-size: 36px !important;
                        }
                        
                        .m-50px-b {
                            margin-bottom: 30px !important;
                        }
                        
                        .text-md-right {
                            text-align: center !important;
                        }
                    }
                `}</style>
            </section>
        );
    }
}

export default RestaurantPricing; 