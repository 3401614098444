import React, { Component } from 'react';
import homedata from '../../data/homedata.json';
import '../../css/srcStyle.css'

class Feature extends Component {
    openInNewTab = (url) => {
        const newWindow = window.open(url, '_blank', 'noopener,noreferrer');
        if (newWindow) newWindow.opener = null;
    }

    render() {
        let publicUrl = process.env.PUBLIC_URL;
        let imgattr = 'image';
        return (
            <section id="feature" className="p-80px-tb position-relative" style={{ background: 'linear-gradient(135deg, #f5f7fa 0%, #eef1f5 100%)' }}>
                <div className="container">
                    <div className="row">
                        <div className="col-sm-10 offset-sm-1">
                            <div className="section-title text-center m-50px-b">
                                <h2>Beyond Voice: AI Chat Agents Across All Platforms</h2>
                                <p className="lead">Not just voice AI - our intelligent assistants engage your pizza store customers through text wherever they are</p>
                            </div>
                        </div>
                    </div>

                    <div className="row align-items-center">
                        <div className="col-lg-6 col-md-12 mb-5 mb-lg-0">
                            <h3 style={{ color: '#2f80ed', fontWeight: '700', marginBottom: '1.5rem' }}>
                                One AI Assistant, Multiple Channels for Your Restaurant
                            </h3>
                            <p style={{ fontSize: '1.1rem', lineHeight: '1.7', color: '#555', marginBottom: '2rem' }}>
                                <strong>Beyond voice calls</strong>, our AI chat agents seamlessly integrate with the text-based platforms your pizza shop customers already use every day. Whether they prefer WhatsApp, Instagram, or your website chat, our AI delivers consistent, intelligent responses to enhance customer engagement.
                            </p>
                            
                            <div className="platform-features">
                                <div className="feature-item mb-4 d-flex align-items-start">
                                    <div className="icon-wrapper me-3" style={{ 
                                        backgroundColor: '#2f80ed', 
                                        borderRadius: '50%',
                                        width: '50px',
                                        height: '50px',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        flexShrink: 0
                                    }}>
                                        <i className="icofont-brand-whatsapp" style={{ color: 'white', fontSize: '24px' }}></i>
                                    </div>
                                    <div>
                                        <h4>WhatsApp Business Integration</h4>
                                        <p>Connect directly with customers on WhatsApp. Our AI handles inquiries, sends order confirmations, processes payments, and more through WhatsApp Business API.</p>
                                    </div>
                                </div>
                                
                                <div className="feature-item mb-4 d-flex align-items-start">
                                    <div className="icon-wrapper me-3" style={{ 
                                        backgroundColor: '#E1306C', 
                                        borderRadius: '50%',
                                        width: '50px',
                                        height: '50px',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        flexShrink: 0
                                    }}>
                                        <i className="icofont-instagram" style={{ color: 'white', fontSize: '24px' }}></i>
                                    </div>
                                    <div>
                                        <h4>Instagram Messaging</h4>
                                        <p>Automatically respond to Instagram DMs with our AI assistant. Respond to comments, answer questions about products, and engage with followers 24/7.</p>
                                    </div>
                                </div>
                                
                                <div className="feature-item d-flex align-items-start">
                                    <div className="icon-wrapper me-3" style={{ 
                                        backgroundColor: '#20C997', 
                                        borderRadius: '50%',
                                        width: '50px',
                                        height: '50px',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        flexShrink: 0
                                    }}>
                                        <i className="icofont-web" style={{ color: 'white', fontSize: '24px' }}></i>
                                    </div>
                                    <div>
                                        <h4>Website Chat Widget</h4>
                                        <p>Add our intelligent chat widget to your pizza store website to assist visitors, answer questions, and guide them through your menu—all without human intervention.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12 text-center">
                            <div style={{ 
                                position: 'relative',
                                maxWidth: '350px',
                                margin: '0 auto'
                            }}>
                                <img 
                                    src="/assets/img/Subject 5.png" 
                                    alt="AI Chat Mockup"
                                    className="img-fluid"
                                    style={{ position: 'relative', zIndex: 0 }}
                                />
                                
                                <div className="platform-icons" style={{
                                    position: 'absolute',
                                    top: '10px',
                                    right: '-40px',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    gap: '15px',
                                    zIndex: 2
                                }}>
                                    <div style={{ 
                                        backgroundColor: 'white', 
                                        borderRadius: '50%', 
                                        width: '60px', 
                                        height: '60px',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        boxShadow: '0 5px 15px rgba(0,0,0,0.1)'
                                    }}>
                                        <i className="icofont-brand-whatsapp" style={{ fontSize: '30px', color: '#25D366' }}></i>
                                    </div>
                                    <div style={{ 
                                        backgroundColor: 'white', 
                                        borderRadius: '50%', 
                                        width: '60px', 
                                        height: '60px',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        boxShadow: '0 5px 15px rgba(0,0,0,0.1)'
                                    }}>
                                        <i className="icofont-instagram" style={{ fontSize: '30px', color: '#E1306C' }}></i>
                                    </div>
                                    <div style={{ 
                                        backgroundColor: 'white', 
                                        borderRadius: '50%', 
                                        width: '60px', 
                                        height: '60px',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        boxShadow: '0 5px 15px rgba(0,0,0,0.1)'
                                    }}>
                                        <i className="icofont-web" style={{ fontSize: '30px', color: '#20C997' }}></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    <div className="row mt-5 pt-4">
                        <div className="col-12">
                            <div style={{
                                backgroundColor: 'white',
                                borderRadius: '12px',
                                padding: '2rem',
                                boxShadow: '0 5px 15px rgba(0,0,0,0.05)'
                            }}>
                                <h3 className="text-center mb-4" style={{ color: '#333' }}>Key Benefits of Our AI Chat Agents</h3>
                                
                                <div className="row text-center">
                                    <div className="col-md-4 mb-4 mb-md-0">
                                        <div style={{ 
                                            height: '70px',
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            marginBottom: '15px'
                                        }}>
                                            <i className="icofont-clock-time" style={{ fontSize: '45px', color: '#2f80ed' }}></i>
                                        </div>
                                        <h4 style={{ fontSize: '1.25rem', color: '#333', marginBottom: '10px' }}>24/7 Availability</h4>
                                        <p style={{ color: '#666' }}>Never miss another customer inquiry with round-the-clock automated responses.</p>
                                    </div>
                                    
                                    <div className="col-md-4 mb-4 mb-md-0">
                                        <div style={{ 
                                            height: '70px',
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            marginBottom: '15px'
                                        }}>
                                            <i className="icofont-people" style={{ fontSize: '45px', color: '#2f80ed' }}></i>
                                        </div>
                                        <h4 style={{ fontSize: '1.25rem', color: '#333', marginBottom: '10px' }}>Personalized Experience</h4>
                                        <p style={{ color: '#666' }}>Our AI remembers customer preferences and history for tailored interactions.</p>
                                    </div>
                                    
                                    <div className="col-md-4">
                                        <div style={{ 
                                            height: '70px',
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            marginBottom: '15px'
                                        }}>
                                            <i className="icofont-automation" style={{ fontSize: '45px', color: '#2f80ed' }}></i>
                                        </div>
                                        <h4 style={{ fontSize: '1.25rem', color: '#333', marginBottom: '10px' }}>Automated Actions</h4>
                                        <p style={{ color: '#666' }}>Beyond conversation, our AI can process orders, verify information, and update systems.</p>
                                    </div>
                                </div>
                                
                                <div className="text-center mt-4">
                                    <a href="#contact" className="btn btn-default btn-filled animated-btn">Get Started with AI Chat</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default Feature;
