import React, { Component } from 'react';
import { HashLink as HLink } from 'react-router-hash-link';

class WhyChooseUs extends Component {
    render() {
        return (
            <section id="why-choose-us" className="p-80px-tb" style={{ background: 'linear-gradient(135deg, #f8f9fa 0%, #e9ecef 100%)' }}>
                <div className="container">
                    {/* Stats Section */}
                    <div className="row mb-5">
                        <div className="col-12 text-center mb-5">
                            <h2 style={{ color: '#333', fontSize: '2.5rem', fontWeight: '700' }}>The Hidden Cost of Missed Calls</h2>
                            <p className="lead mb-4" style={{ fontSize: '1.25rem', color: '#555', maxWidth: '800px', margin: '0 auto' }}>
                                Our AI-powered phone assistant ensures restaurants and pizza shops never miss another opportunity to increase revenue.
                            </p>
                        </div>
                        <div className="col-md-4">
                            <div className="text-center p-4" style={{ 
                                backgroundColor: 'white', 
                                borderRadius: '10px',
                                boxShadow: '0 5px 15px rgba(0,0,0,0.05)',
                                height: '100%'
                            }}>
                                <h2 className="display-4 font-weight-bold" style={{ color: '#2f80ed' }}>110+</h2>
                                <p className="mb-0" style={{ fontSize: '1.1rem', color: '#666' }}>
                                    Minutes restaurants spend daily on phone communication
                                </p>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="text-center p-4" style={{ 
                                backgroundColor: 'white', 
                                borderRadius: '10px',
                                boxShadow: '0 5px 15px rgba(0,0,0,0.05)',
                                height: '100%'
                            }}>
                                <h2 className="display-4 font-weight-bold" style={{ color: '#2f80ed' }}>45%</h2>
                                <p className="mb-0" style={{ fontSize: '1.1rem', color: '#666' }}>
                                    Of restaurant calls go unanswered during peak hours
                                </p>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="text-center p-4" style={{ 
                                backgroundColor: 'white', 
                                borderRadius: '10px',
                                boxShadow: '0 5px 15px rgba(0,0,0,0.05)',
                                height: '100%'
                            }}>
                                <h2 className="display-4 font-weight-bold" style={{ color: '#2f80ed' }}>60%</h2>
                                <p className="mb-0" style={{ fontSize: '1.1rem', color: '#666' }}>
                                    Of customers hang up and order elsewhere when put on hold
                                </p>
                            </div>
                        </div>
                    </div>

                    {/* Benefits Section */}
                    <div className="row mt-5 pt-3">
                        <div className="col-12 text-center mb-5">
                            <h2 style={{ color: '#333' }}>Meet Your Restaurant's New Star Employee</h2>
                            <p className="lead" style={{ color: '#666' }}>Our AI phone assistant works around the clock at your restaurant or pizza store without breaks, sick days, or training periods</p>
                        </div>
                        
                        <div className="col-md-6 mb-4">
                            <div className="d-flex p-4" style={{ 
                                backgroundColor: 'white', 
                                borderRadius: '10px',
                                boxShadow: '0 5px 15px rgba(0,0,0,0.05)',
                                height: '100%'
                            }}>
                                <div className="mr-4">
                                    <i className="icofont-clock-time" style={{ fontSize: '2.5rem', color: '#2f80ed' }}></i>
                                </div>
                                <div>
                                    <h3 style={{ color: '#333', fontSize: '1.5rem' }}>Never Miss Another Pizza Order</h3>
                                    <p style={{ color: '#666' }}>
                                        Capture every potential order with 24/7 phone coverage that answers every call instantly, even during your pizza shop's busiest hours.
                                    </p>
                                </div>
                            </div>
                        </div>
                        
                        <div className="col-md-6 mb-4">
                            <div className="d-flex p-4" style={{ 
                                backgroundColor: 'white', 
                                borderRadius: '10px',
                                boxShadow: '0 5px 15px rgba(0,0,0,0.05)',
                                height: '100%'
                            }}>
                                <div className="mr-4">
                                    <i className="icofont-people" style={{ fontSize: '2.5rem', color: '#2f80ed' }}></i>
                                </div>
                                <div>
                                    <h3 style={{ color: '#333', fontSize: '1.5rem' }}>Free Up Your Staff</h3>
                                    <p style={{ color: '#666' }}>
                                        Let your team focus on providing excellent in-house service while our AI handles all incoming calls and orders.
                                    </p>
                                </div>
                            </div>
                        </div>
                        
                        <div className="col-md-6 mb-4">
                            <div className="d-flex p-4" style={{ 
                                backgroundColor: 'white', 
                                borderRadius: '10px',
                                boxShadow: '0 5px 15px rgba(0,0,0,0.05)',
                                height: '100%'
                            }}>
                                <div className="mr-4">
                                    <i className="icofont-money" style={{ fontSize: '2.5rem', color: '#2f80ed' }}></i>
                                </div>
                                <div>
                                    <h3 style={{ color: '#333', fontSize: '1.5rem' }}>Reduce Costs, Increase Profits</h3>
                                    <p style={{ color: '#666' }}>
                                        Lower labor costs while increasing order accuracy and capturing revenue that would otherwise be lost to missed calls.
                                    </p>
                                </div>
                            </div>
                        </div>
                        
                        <div className="col-md-6 mb-4">
                            <div className="d-flex p-4" style={{ 
                                backgroundColor: 'white', 
                                borderRadius: '10px',
                                boxShadow: '0 5px 15px rgba(0,0,0,0.05)',
                                height: '100%'
                            }}>
                                <div className="mr-4">
                                    <i className="icofont-chart-growth" style={{ fontSize: '2.5rem', color: '#2f80ed' }}></i>
                                </div>
                                <div>
                                    <h3 style={{ color: '#333', fontSize: '1.5rem' }}>Boost Customer Satisfaction</h3>
                                    <p style={{ color: '#666' }}>
                                        Create a seamless customer experience with zero wait times, consistent service, and perfect order accuracy.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* AI Capabilities Section - NEWLY ADDED */}
                    <div className="row mt-5 pt-3">
                        <div className="col-12 text-center mb-5">
                            <h2 style={{ color: '#333' }}>Smart Solutions for Common Restaurant Challenges</h2>
                            <p className="lead" style={{ color: '#666' }}>Our AI assistant solves problems that your current systems can't</p>
                        </div>
                        
                        <div className="col-md-6 mb-4">
                            <div className="d-flex p-4" style={{ 
                                backgroundColor: 'white', 
                                borderRadius: '10px',
                                boxShadow: '0 5px 15px rgba(0,0,0,0.05)',
                                height: '100%'
                            }}>
                                <div className="mr-4">
                                    <i className="icofont-phone" style={{ fontSize: '2.5rem', color: '#2f80ed' }}></i>
                                </div>
                                <div>
                                    <h3 style={{ color: '#333', fontSize: '1.5rem' }}>Concurrent Calls</h3>
                                    <p style={{ color: '#666' }}>
                                        <span style={{ fontWeight: '600', display: 'block', marginBottom: '8px', color: '#2f80ed' }}>
                                            How many concurrent calls can you handle during peak hours?
                                        </span>
                                        Our AI can handle them all at once! Never miss another order during your busiest times.
                                    </p>
                                </div>
                            </div>
                        </div>
                        
                        <div className="col-md-6 mb-4">
                            <div className="d-flex p-4" style={{ 
                                backgroundColor: 'white', 
                                borderRadius: '10px',
                                boxShadow: '0 5px 15px rgba(0,0,0,0.05)',
                                height: '100%'
                            }}>
                                <div className="mr-4">
                                    <i className="icofont-chart-growth" style={{ fontSize: '2.5rem', color: '#2f80ed' }}></i>
                                </div>
                                <div>
                                    <h3 style={{ color: '#333', fontSize: '1.5rem' }}>Automatic Upselling</h3>
                                    <p style={{ color: '#666' }}>
                                        <span style={{ fontWeight: '600', display: 'block', marginBottom: '8px', color: '#2f80ed' }}>
                                            What's your average order value?
                                        </span>
                                        Our AI increases it by up to 25% by intelligently upselling high-margin items like drinks, desserts, or any products you prioritize.
                                    </p>
                                </div>
                            </div>
                        </div>
                        
                        <div className="col-md-6 mb-4">
                            <div className="d-flex p-4" style={{ 
                                backgroundColor: 'white', 
                                borderRadius: '10px',
                                boxShadow: '0 5px 15px rgba(0,0,0,0.05)',
                                height: '100%'
                            }}>
                                <div className="mr-4">
                                    <i className="icofont-history" style={{ fontSize: '2.5rem', color: '#2f80ed' }}></i>
                                </div>
                                <div>
                                    <h3 style={{ color: '#333', fontSize: '1.5rem' }}>Order History Recognition</h3>
                                    <p style={{ color: '#666' }}>
                                        <span style={{ fontWeight: '600', display: 'block', marginBottom: '8px', color: '#2f80ed' }}>
                                            If a loyal customer says, 'Repeat my last order', can your current system handle it?
                                        </span>
                                        Our AI completes it in seconds, enhancing customer loyalty and streamlining the ordering process.
                                    </p>
                                </div>
                            </div>
                        </div>
                        
                        <div className="col-md-6 mb-4">
                            <div className="d-flex p-4" style={{ 
                                backgroundColor: 'white', 
                                borderRadius: '10px',
                                boxShadow: '0 5px 15px rgba(0,0,0,0.05)',
                                height: '100%'
                            }}>
                                <div className="mr-4">
                                    <i className="icofont-location-pin" style={{ fontSize: '2.5rem', color: '#2f80ed' }}></i>
                                </div>
                                <div>
                                    <h3 style={{ color: '#333', fontSize: '1.5rem' }}>Smart Delivery Pricing</h3>
                                    <p style={{ color: '#666' }}>
                                        <span style={{ fontWeight: '600', display: 'block', marginBottom: '8px', color: '#2f80ed' }}>
                                            Charging different delivery fees by distance?
                                        </span>
                                        Our AI auto-detects addresses and applies the right fee in just 2 seconds, ensuring fair pricing and maximizing delivery profits.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* CTA Section */}
                    <div className="row mt-5 pt-4">
                        <div className="col-lg-8 offset-lg-2 text-center">
                            <h2 style={{ color: '#333' }}>Ready to Transform Your Restaurant or Pizza Shop's Phone Service?</h2>
                            <p className="lead mb-4" style={{ color: '#666' }}>
                                Join the restaurants and pizza stores that are increasing revenue, reducing costs, and improving customer satisfaction with our AI phone assistant.
                            </p>
                            <div className="d-flex justify-content-center">
                                <HLink to="/restaurant-form" className="btn btn-default btn-filled animated-btn mr-3" style={{ 
                                    backgroundColor: '#2f80ed', 
                                    borderColor: '#2f80ed',
                                    padding: '12px 30px',
                                    fontSize: '1.1rem'
                                }}>
                                    Apply as a Restaurant
                                </HLink>
                                <HLink to="/#demo" className="btn btn-default btn-filled animated-btn" style={{ 
                                    backgroundColor: '#2f80ed', 
                                    borderColor: '#2f80ed',
                                    color: 'white',
                                    padding: '12px 30px',
                                    fontSize: '1.1rem'
                                }}>
                                    See Demo First
                                </HLink>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default WhyChooseUs; 