import React, { useState } from 'react';
import axios from 'axios';
import homedata from '../../data/homedata.json';
import { handleScheduleClick } from '../../utils';

const Contact = ({ showNotification, hideRestaurantButton }) => {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        businessName: '',
        phone: '',
        message: '',
    });

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({ ...prevData, [name]: value }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        const { name, email, businessName, phone, message } = formData;

        const data = {
            content: `Name: ${name}<br>Email: ${email}<br>Business Name: ${businessName}<br>Phone: ${phone}<br>Message: ${message}`,
            mailFormat: 'html',
        };

        axios
            .post('https://api.aibunny.tech/router/send-email', data)
            .then((response) => {
                if (response.data.status === "ok") {
                    showNotification('Email sent successfully!', 'success');
                    setFormData({ name: '', email: '', businessName: '', phone: '', message: '' });
                } else {
                    showNotification('Error, try again later.', 'error');
                }
                console.log('Email response:', response.data);
            })
            .catch((error) => {
                showNotification('Error, try again later.', 'error');
                console.error('There was an error sending the email:', error);
                if (error.response) {
                    console.log('Response data:', error.response.data);
                    console.log('Response status:', error.response.status);
                    console.log('Response headers:', error.response.headers);
                }
            });
    };

    return (
        <section id="contact" className="p-80px-tb">
            <div className="container">
                <div className="row">
                    <div className="col-sm-8 offset-sm-2">
                        <div className="section-title text-center m-50px-b">
                            <h2>{homedata.contact.title}</h2>
                        </div>
                    </div>
                </div>
                <div className="row row-eq-height">
                    <div className="col-lg-4 col-md-6 contact-address p-30px">
                        <div className="address-box text-center p-15px m-15px-b">
                            <i className={homedata.contact.contactinfo.phone.icon}></i>
                            <h5>{homedata.contact.contactinfo.phone.title}</h5>
                            <p dangerouslySetInnerHTML={{ __html: homedata.contact.contactinfo.phone.info }}></p>
                        </div>
                        <div className="address-box text-center p-15px">
                            <i className={homedata.contact.contactinfo.email.icon}></i>
                            <h5>{homedata.contact.contactinfo.email.title}</h5>
                            <p dangerouslySetInnerHTML={{ __html: homedata.contact.contactinfo.email.info }}></p>
                        </div>
                    </div>
                    <div className="col-lg-8 col-md-6 contact-form p-30px">
                        <h3 className="m-25px-b">{homedata.contact.formtitle}</h3>
                        <p className="m-25px-b">{homedata.contact.formdescription}</p>
                        <form id="contact-form" onSubmit={handleSubmit}>
                            <div className="mb13">
                                <input
                                    name="name"
                                    className="contact-name"
                                    id="contact-name"
                                    type="text"
                                    placeholder="Your Name"
                                    required=""
                                    onChange={handleInputChange}
                                    value={formData.name}
                                />
                            </div>
                            <div className="mb13">
                                <input
                                    name="email"
                                    className="contact-email"
                                    id="contact-email"
                                    type="email"
                                    placeholder="Your Email"
                                    required=""
                                    onChange={handleInputChange}
                                    value={formData.email}
                                />
                            </div>
                            <div className="mb13">
                                <input
                                    name="businessName"
                                    className="contact-subject"
                                    id="contact-subject"
                                    type="text"
                                    placeholder="Your Business Name"
                                    required=""
                                    onChange={handleInputChange}
                                    value={formData.businessName}
                                />
                            </div>
                            <div className="mb13">
                                <input
                                    name="phone"
                                    className="contact-phone"
                                    id="contact-phone"
                                    type="text"
                                    placeholder="Your Phone Number"
                                    required=""
                                    onChange={handleInputChange}
                                    value={formData.phone}
                                />
                            </div>
                            <div className="mb30">
                                <textarea
                                    name="message"
                                    className="contact-message"
                                    id="contact-message"
                                    placeholder="Your Message"
                                    required=""
                                    onChange={handleInputChange}
                                    value={formData.message}
                                ></textarea>
                            </div>
                            <div className="button-group" style={{ display: 'flex', flexDirection: 'column', gap: '15px', marginTop: '25px' }}>
                                <div style={{ display: 'flex', gap: '15px' }}>
                                    <button className="btn btn-default btn-filled animated-btn" type="submit" style={{ flex: '1' }}>
                                        Send Now
                                    </button>
                                    <a
                                        href="https://aibunny.zohobookings.com/#/aibunny"
                                        className="btn btn-default btn-filled animated-btn"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        style={{ flex: '1' }}
                                    >
                                        Or Schedule a Meeting
                                    </a>
                                </div>
                                {!hideRestaurantButton && (
                                <div style={{ borderTop: '1px solid #eee', paddingTop: '15px' }}>
                                    <a
                                        href="/restaurant-form"
                                        className="btn btn-default btn-filled animated-btn"
                                        style={{ 
                                            width: '100%', 
                                            background: '#4F46E5',
                                            borderColor: '#4F46E5',
                                            color: 'white',
                                            fontWeight: '600'
                                        }}
                                    >
                                        Are you a pizza store/Restaurant? Apply here!
                                    </a>
                                </div>
                                )}
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Contact;
